import {
    Box,
    Container,
    Flex,
    Heading,
    Stack,
    Text,
    Image,
    useColorModeValue,
  } from '@chakra-ui/react';
  import Benefit1 from '../assets/benefit1.png'
  import Benefit2 from '../assets/benefit2.png'
  import Benefit3 from '../assets/benefit3.png'
  import Benefit4 from '../assets/benefit4.png'
  import Benefit5 from '../assets/benefit5.png'
  import Benefit6 from '../assets/benefit6.png'
  import Benefit7 from '../assets/benefit7.png'
  import Benefit8 from '../assets/benefit8.png'
  import Benefit9 from '../assets/benefit9.png'
  
  const Card = ({ heading, description, icon }) => {
    return (
      <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={'start'} spacing={2}>
          <Flex
            w={16}
            h={16}
            align={'center'}
            justify={'center'}
            color={'white'}
            rounded={'full'}
            bg={useColorModeValue('gray.100', 'gray.700')}>
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'sm'}>
              {description}
            </Text>
          </Box>
        </Stack>
      </Box>
    );
  };
  
  export default function Benefits() {
    return (
      <Box p={4}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
            Benefits of Working With Us
          </Heading>
          <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
            Below are the benfits that you will get on getting onboarded with us as clients.
          </Text>
        </Stack>
  
        <Container maxW={'5xl'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            <Card
              heading={'24/7 technical support'}
              icon={<Image src={Benefit1} w={10} h={10} />}
              description={
                'Technical Support all day long.'
              }
            />
            <Card
              heading={'Cost savings'}
              icon={<Image src={Benefit2} w={10} h={10} />}
              description={
                'Cost Savings on all your IT spends.'
              }
            />
            <Card
              heading={'Customized services'}
              icon={<Image src={Benefit3} w={10} h={10} />}
              description={
                'Services Customized as per your needs.'
              }
            />
            <Card
              heading={'Certified cloud consulting experts'}
              icon={<Image src={Benefit4} w={10} h={10} />}
              description={
                'Experienced, Best In class team supporting you.'
              }
            />
            <Card
              heading={'Operational Excellence'}
              icon={<Image src={Benefit5} w={10} h={10} />}
              description={
                'Greater flexibility and operational efficiency.'
              }
            />
            <Card
              heading={'Governance & Compliance'}
              icon={<Image src={Benefit6} w={10} h={10} />}
              description={
                'Improved IT security, governance & management.'
              }
            />
            <Card
              heading={'Enhanced scalability'}
              icon={<Image src={Benefit7} w={10} h={10} />}
              description={
                'Stablize your business IT needs.'
              }
            />
            <Card
              heading={'Quick Delivery'}
              icon={<Image src={Benefit8} w={10} h={10} />}
              description={
                'Faster time to market and application development.'
              }
            />
            <Card
              heading={'Journey'}
              icon={<Image src={Benefit9} w={10} h={10} />}
              description={
                'Seamless cloud journey.'
              }
            />
          </Flex>
        </Container>
      </Box>
    );
  }