import React from 'react'
import {Flex,Text,Heading,Image} from '@chakra-ui/react'
import Explore from './Explore'
import ContactButton from '../ContactButton'

export default function CarouselComp({Heading1, Heading2, Tagline1, Tagline2, Img, Linkto}) {
  return (
    <>
    <Flex align="center" justify="center" textAlign='center' fontWeight="bold" bgColor="#22577A" color='white' fontsize='sm' px='5%' my='1%'>Data Science = You Give Data + We at USRYA will give Science</Flex>
    <Flex direction={{base:"column",md:"row"}} marginBottom={{base:'25%',md:'10%'}} id='home' justify={'space-evenly'} alignItems='center'> 
        <Flex direction='column' align={{base:'center',md:'flex-start'}} justify='flex-start' wrap='nowrap' textAlign={{base:'center',md:'left'}} data-aos='fade-down'>
            <Heading fontSize={{base:'2xl',md:'5xl'}} fontWeight='920' color="#22577A" letterSpacing='1px' display='flex' alignItems={{base:'center',md:'flex-start'}} flexDirection='column'> 
                <Text>{Heading1}</Text>
                <Text>{Heading2}</Text>
            </Heading>
            <Heading fontSize={{base:'small',md:'md'}} fontWeight='200' color="black" letterSpacing='1px' display='flex' alignItems={{base:'center',md:'flex-start'}} flexDirection='column' mt={'2%'}> 
                <Text lineHeight={'6'}>  {Tagline1}</Text>
                <Text lineHeight={'6'}>  {Tagline2}</Text>
            </Heading>
            <Flex direction={'row'} justifyContent='space-between' mt='4%'>
            <Explore redirect={Linkto}/>
            <ContactButton/>
            </Flex>
        </Flex>
        <Image objectFit={{base:'cover',md:'fill'}} src={Img} alt='Main SVG' boxSize={{base:'80%',md:'40%'}} mt='10' zIndex={-2} data-aos='fade-up'></Image>
    </Flex>
    </>
  )
}