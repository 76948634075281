import { Flex,Tabs, TabList, TabPanels, Tab, TabPanel,Box,Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, } from '@chakra-ui/react'
import React, {useEffect} from 'react'
import ServiceComp from './landingPageComonents/ServiceComp'
import ServiceDesc from './ServiceDesc'
import Hr1 from '../assets/hr1.png'
import Hr2 from '../assets/hr2.png'
import Hr3 from '../assets/hr3.png'
import First from '../assets/hr/first.png'
import Second from '../assets/hr/second.jpg'
import Third from '../assets/hr/third.jpg'
import AOS from 'aos'

const offerings = [
  {
    icon: Hr1,
    photo: First,
    type:'Offering',
    title: 'Resource Staffing',
    content:[
      "The process of identifying, hiring, and managing the resources required to complete a project or achieve a specific goal. This includes identifying the skills, experience, and knowledge needed for the project, determining the number of resources required, and managing their performance throughout the project.",
      "Resource Planning: This involves identifying the resources needed for the project, including personnel, equipment, and materials. It also involves determining the number of resources required and the timeline for their availability",
      "Resource Acquisition: Once the resources needed have been identified, they must be acquired. This may involve hiring new personnel, procuring equipment, or purchasing materials.",
      "Resource Allocation: After the resources have been acquired, they must be allocated to the project. This involves determining how many resources are needed for each task or phase of the project and assigning specific resources to those tasks.",
      "Resource Management: Once the resources have been allocated, they must be managed throughout the project. This includes monitoring their performance, providing training and support as needed, and resolving any issues that arise."
    ]
  },
  {
    icon: Hr2,
    photo: Second,
    type:'Offering',
    title: 'Resource Outsourcing',
    content:[
      "IT resource outsourcing refers to the practice of hiring external resources to handle various aspects of a company's IT operations. This can include tasks such as software development, network management, cybersecurity, data analytics, and technical support, among others.",
      "IT resource outsourcing can provide many benefits to businesses, such as cost savings, increased access to specialized skills and expertise, and improved flexibility and scalability. By outsourcing IT functions, companies can avoid the need to hire and train in-house staff for specific tasks, which can be both time-consuming and costly."
    ]
  },
  {
    icon: Hr3,
    photo: Third,
    type:'Offering',
    title: 'Talent Assessment',
    content:[
      "Talent assessment refers to the process of evaluating an individual's skills, knowledge, abilities, and personality traits to determine their suitability for a particular job or role within an organization. The goal of talent assessment is to identify individuals who have the potential to be successful in the role and to make informed decisions about hiring, promoting, and developing employees.",
      "Psychometric testing: This involves administering standardized tests or assessments to evaluate an individual's cognitive abilities, personality traits, and other characteristics.",
      "Work sample tests: This involves having candidates perform tasks or simulations that are similar to those they would be expected to perform on the job.",
      "Structured interviews: This involves asking candidates a series of standardized questions designed to assess their skills, knowledge, and abilities.",
      "Reference checks: This involves contacting previous employers or other references provided by the candidate to gather information about their work history, performance, and character.",
      "Assessment centers: This involves bringing candidates together in a simulated work environment to evaluate their performance on a variety of tasks and activities."
    ]
  }
]

export default function HrServices() {

  useEffect(() => {
    AOS.init({duration:1000});
    AOS.refresh();
  },[])

    return (
      <div style={{position:'relative'}}>
        <Box rounded='full' boxSize='900' bgGradient='linear(to-tr, #38A3A5, #C7F9CC)'  filter='auto' blur='80px' position='absolute' top={{base:'-50vh',md:'-70%'}} left={{base:'75%',md:'80%'}} zIndex='-7'/>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'none',md:'block'}}>
            <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'} data-aos='fade-down'>
                Our HR Solutioning Services
            </Text>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'left'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Tabs variant={'enclosed-colored'} orientation='vertical'>
              <TabList ml='5%'>
                {offerings.map((element,key)=>(
                  <Tab _selected={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                  </Tab>
                ))}
              </TabList>
              <TabPanels mr='5%'>
                {offerings.map((element,key)=>(
                  <TabPanel>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
        </Flex>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'block',md:'none'}}>
            <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'}>
              Our HR Solutioning Services
            </Text>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'center'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Accordion defaultIndex={0} allowToggle>
              {offerings.map((element,key) => (
                <AccordionItem id={key}>
                  <AccordionButton _expanded={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </AccordionPanel>
                </AccordionItem>
              )
              )}
            </Accordion>
        </Flex>
        </div>
    )
}
