import React, {useEffect} from 'react'
import AOS from 'aos'
import "aos/dist/aos.css";
import Hero from './landingPageComonents/Hero'
import Howwedo from './landingPageComonents/Howwedo';
import Clients from './Clients';



export default function Landing() {
    useEffect(() => {
        AOS.init({duration:1000});
      }, []);
    return (
        <div >
            <Hero/>
            
            {/* <Whatwedo/> */}
            <Howwedo/>
            <Clients/>
        </div>
    )
}
