import { Flex,Tabs, TabList, TabPanels, Tab, TabPanel,Box,Text, Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center, Link } from '@chakra-ui/react'
import React, {useEffect} from 'react'
import ServiceComp from './landingPageComonents/ServiceComp'
import ServiceDesc from './ServiceDesc'
import ContactButton from './ContactButton'
import Sb1 from '../assets/sb1.png'
import Sb2 from '../assets/sb2.png'
import Sb3 from '../assets/sb3.png'
import First from '../assets/skill/first.jpg'
import Second from '../assets/skill/second.jpg'
import Third from '../assets/skill/third.jpg'
import InternQR from '../assets/intern_qr_2.png'
import Whatsapp from '../assets/WhatsAppButton.png'
import AOS from 'aos'

const offerings = [
  {
    icon: Sb1,
    photo: First,
    type:'Offering',
    title: 'Skill Building',
    content:[
      "Skill building refers to the process of developing new abilities or enhancing existing ones in order to perform tasks more effectively. This can involve a wide range of activities, including training, education, practice, and experience.",
      "Skill building is important in both personal and professional contexts, as it can lead to improved performance, increased confidence, and greater opportunities for advancement. In the workplace, skill building can help employees to stay current with changing technologies and industry trends, as well as to develop the skills necessary to take on new challenges and responsibilities.",
      "Upon successful completion of the course, the candidate will be provided with \"Course Completion Certification\".",
      <>
      Usrya provides integrated training and development on the following technological stacks:
      <br></br>
      1. Cloud Computing (AWS, Azure and Google Cloud)
      <br></br>
      2. Full Stack Development
      <br></br>
      3. AI/ML
      <br></br>
      4. Python
      <br></br>
      5. Core Java
      </>
    ]
  },
  {
    icon: Sb2,
    photo: Second,
    type:'Offering',
    title: 'Academic Projects',
    content:[
        <>
        Helping students (Engineering, MCA, and BCA) in conceptualizing, Designing, Coding, Testing and implementing academic projects on cutting-edge technologies on below technology stacks:
        <br></br>
        1. Cloud Computing (AWS, Azure and Google Cloud)
        <br></br>
        2. Full Stack Development
        <br></br>
        3. AI/ML
        <br></br>
        4. Python
        <br></br>
        5. Core Java
        </>
    ]
  },
  {
    icon: Sb3,
    photo: Third,
    type:'Offering',
    title: 'Internship Program',
    content:[
      
      "An internship program is a structured, short-term work experience offered by Usrya for computer students or recent graduates to gain practical work experience in a particular field. Internships can be paid or unpaid and can be full-time or part-time.",
      "It provides an opportunity to gain valuable work experience, develop professional skills, and make connections in their chosen fields. Internships can also provide employers with an opportunity to identify and recruit potential future employees.",
      "Upon successful completion of the course, candidate will be provided with a Internship certificate."
    ],
    contentImg: <Image src={InternQR} boxSize={{base:'100%',md:'350'}}/>
  }
]

export default function SkillServices() {
  useEffect(() => {
    AOS.init({duration:1000});
    AOS.refresh();
  },[])

    return (
      <div style={{position:'relative'}}>
        <Box rounded='full' boxSize='900' bgGradient='linear(to-tr, #38A3A5, #C7F9CC)'  filter='auto' blur='80px' position='absolute' top={{base:'-50vh',md:'-70%'}} left={{base:'75%',md:'80%'}} zIndex='-7'/>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'none',md:'block'}}>
            <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'} data-aos='fade-down' mb='2%'>
                Our Skill Builder Services
            </Text>
            <Center>
              <ContactButton/>
              <Link href='https://wa.me/918618144764' isExternal><Image src={Whatsapp} boxSize='60%'></Image></Link>
            </Center>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'left'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Tabs variant={'enclosed-colored'} orientation='vertical'>
              <TabList ml='5%'>
                {offerings.map((element,key)=>(
                  <Tab _selected={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                  </Tab>
                ))}
              </TabList>
              <TabPanels mr='5%'>
                {offerings.map((element,key)=>(
                  <TabPanel>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content} ContentImg={element.contentImg}/>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
        </Flex>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'block',md:'none'}}>
            <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'} mb='2%'>
              Our Skill Builder Services
            </Text>
            <Center>
              <ContactButton/>
              <Link href='https://wa.me/918618144764' isExternal><Image src={Whatsapp} boxSize='60%'></Image></Link>
            </Center>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'center'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Accordion defaultIndex={0} allowToggle>
              {offerings.map((element,key) => (
                <AccordionItem id={key}>
                  <AccordionButton _expanded={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content} ContentImg={element.contentImg}/>
                  </AccordionPanel>
                </AccordionItem>
              )
              )}
            </Accordion>
        </Flex>
        
        </div>
    )
}
