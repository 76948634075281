import React from 'react'
import {Box, Flex, Image, Link} from '@chakra-ui/react'
import ApplyPng from '../assets/apply.png'

export default function Apply() {
    return (
        <Flex direction='column' align='center' justify='space-around' m='5' >
            <Box p='4'
                m='5' 
                bgGradient = 'linear(to-tr, #38A3A5, #C7F9CC)' 
                color='black' 
                rounded='xl'  
                boxShadow='xl' 
                w={{base:'80%',md:'80%'}} align='center' fontWeight='bold'>
                Please send your Resume/CV to <Link href='mailto:career@usrya.com' textDecoration={"underline"}>career@usrya.com</Link>
            </Box>
            <Image objectFit='fill' src={ApplyPng} w={{base:'80%',md:'60%'}}></Image>
        </Flex>
    )
}
