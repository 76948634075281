import './App.css';
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Landing from './components/Landing'
import About from './components/About'
import Contact from './components/Contact'
import ContactFLoat from './components/ContactFloat'
import Footer from './components/Footer'
import CloudServices from './components/CloudServices'
import ProductServices from './components/ProductServices'
import HrServices from './components/HrServices';
import SkillServices from './components/SkillServices';
import Career from './components/Career';
import Blogs from './components/Blogs'

function App() {
  return (
    <div style={{overflowX:'hidden'}}>
      <BrowserRouter>
        <Navbar/>
        <Switch>
          <Route exact path="/" component={Landing}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/services" component={CloudServices}/>
          <Route exact path="/productservices" component={ProductServices}/>
          <Route exact path="/hrservices" component={HrServices}/>
          <Route exact path="/skillservices" component={SkillServices}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/career" component={Career}/>
          <Route exact path="/blog" component={Blogs}/>
        </Switch>
        <Footer/>
        <ContactFLoat/>
      </BrowserRouter>
    </div>
  );
}

export default App;
