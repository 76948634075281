import { Flex, Text, Stack, Avatar, Box, List, ListIcon, ListItem } from '@chakra-ui/react';
import React, {useEffect} from 'react'
import Pradeep from '../assets/pradeep.jpeg'
import Sanjay from '../assets/sanjay.jpeg'
import Sanghamitra from '../assets/sanghamitra.jpeg'
import Badge from './LinkedinBadge'
import Clients from './Clients';

import { ArrowForwardIcon } from '@chakra-ui/icons';

import AOS from 'aos'


const TestimonialAvatar = ({
    src,
    name,
    title,
  }) => {
    return (
      <Flex align='center' mb={5} direction={'row'}>
        <Avatar src={src} alt={name} mr={2}/>
        <Stack spacing={-1} align={'flex-start'}>
          <Text fontWeight={600} color="#233862">{name}</Text>
          <Text fontSize={'sm'} color={"#233862"}>
            {title}
          </Text>
        </Stack>
      </Flex>
    );
  };

export default function About() {
    useEffect(() => {
    
      AOS.init({duration:1000});
      AOS.refresh();

    }, []);

    return (
      <div>
        <Flex direction={{base:'column',md:'column'}} align='center' justify={{base:'center',md:'space-around'}} color='#233862' mb='10%'>
              <Text fontSize='4xl' my='3' fontWeight='semibold' textAlign={'center'}>
                  Our Team
              </Text>
              <Flex direction={{base:'column',md:'row'}} align='center' justify='space-between' >
                <Badge DisplayPhoto={Pradeep} Name="Pradeep Kumar Nanda" Designation="Founder and CEO at Usrya Artivision Pvt Ltd" ProfileLink="https://in.linkedin.com/in/pknanda?trk=public-profile-badge-profile-badge-view-profile-cta"/>
                <Badge DisplayPhoto={Sanghamitra} Name="Sanghamitra Satpathy" Designation="Co-Founder at Usrya Artivision Pvt Ltd" ProfileLink="https://in.linkedin.com/in/sanghamitrasatpathy?trk=public-profile-badge-profile-badge-view-profile-cta"/>
                <Badge DisplayPhoto={Sanjay}  Name="Sanjay Kumar Gadnayak" Designation="Chief Technical Officer at Usrya Artivision Pvt Ltd" ProfileLink="https://in.linkedin.com/in/sanjay-kumar-gadanayak-3872a3162?trk=public-profile-badge-profile-badge-view-profile-cta"/>
              </Flex>
            <Flex direction='column' align='flex-start' justify='space-around' color='#233862' m={{base:'10',md:'20'}} data-aos='fade-down'>
              
              
                <Text fontSize='4xl' my='3' fontWeight='semibold' textAlign={'center'}>
                    About Us
                </Text>
                <Box rounded='2xl' boxShadow={'dark-lg'} p='5' align={'flex-start'}>
                  {/* <Text fontSize='3xl' my='3' fontWeight='semibold' textAlign={'start'}>
                      Cloud Services
                  </Text> */}
                  <List spacing={'3em'}>
                    <ListItem>
                      <ListIcon as={ArrowForwardIcon} color="green.900" />
                      Our company is run by a team of experienced professionals with extensive backgrounds in the IT industry. We are passionate about helping businesses leverage the power of the cloud to transform their operations and achieve their goals.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={ArrowForwardIcon} color="green.900" />
                      Our team includes cloud architects, engineers, and developers who have worked with a variety of cloud platforms, including AWS, Azure, and Google Cloud. We have experience working with businesses of all sizes, from startups to large enterprises, across a wide range of industries.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={ArrowForwardIcon} color="green.900" />
                      Our mission is to help businesses navigate the complexities of the cloud and leverage its benefits to drive growth and innovation. We understand that every business is unique, and we take a customized approach to each project we undertake. Our team works closely with our clients to understand their specific needs and develop tailored solutions that meet their requirements.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={ArrowForwardIcon} color="green.900" />
                      We are committed to providing exceptional customer service and support throughout the entire cloud journey. Our team is always available to answer questions, provide guidance, and address any issues that arise. We believe in building long-term relationships with our clients and are dedicated to their success.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={ArrowForwardIcon} color="green.900" />
                      At USRYA Cloud Solutioning, we stay up-to-date with the latest cloud technologies and industry trends to ensure our clients are always ahead of the curve. We are constantly learning and evolving to provide the best possible solutions for our clients.
                    </ListItem>
                  </List>
                </Box>
            </Flex>
        </Flex>
        <Clients/>
        </div>
    )
}
