import {
    Heading,
    Avatar,
    Box,
    Text,
    Stack,
    Button,
    Link,
    useColorModeValue,
    Image,
    Flex
  } from '@chakra-ui/react';
  import Linkedin from '../assets/linkedin.svg'
  
  export default function SocialProfileSimple({DisplayPhoto, Name, Designation, ProfileLink}) {
    return (
      <Flex py={6} direction='column' m='10'>
        <Box bg='gray.200' p='4' borderTopRadius={'lg'} maxW={'320px'}
          w={'full'}>
            <Image src={Linkedin} boxSize='35%'/>
          </Box>
        <Box
          maxW={'320px'}
          w={'full'}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'lg'}
          p={6}
          textAlign={'left'}>
          <Avatar
            size={'xl'}
            src={DisplayPhoto}
            alt={'Avatar Alt'}
            mb={4}
            pos={'relative'}
          />
          <Heading fontSize={'xl'} fontFamily={'body'}>
            {Name}
          </Heading>
          <Text
            textAlign={'left'}
            color={useColorModeValue('gray.700', 'gray.400')}
            pt={3}
            >
            {Designation}
          </Text>
  
          {/* <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
            <Badge
              px={2}
              py={1}
              bg={useColorModeValue('gray.50', 'gray.800')}
              fontWeight={'400'}>
              #art
            </Badge>
            <Badge
              px={2}
              py={1}
              bg={useColorModeValue('gray.50', 'gray.800')}
              fontWeight={'400'}>
              #photography
            </Badge>
            <Badge
              px={2}
              py={1}
              bg={useColorModeValue('gray.50', 'gray.800')}
              fontWeight={'400'}>
              #music
            </Badge>
          </Stack> */}
  
          <Stack mt={8} direction={'row'} spacing={4}>
            {/* <Button
              flex={1}
              fontSize={'sm'}
              rounded={'full'}
              _focus={{
                bg: 'gray.200',
              }}>
              Message
            </Button> */}
            <Button
              flex={1}
              fontSize={'sm'}
              rounded={'full'}
              bg={'blue.400'}
              color={'white'}
              boxShadow={
                '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
              }
              _hover={{
                bg: 'blue.500',
              }}
              _focus={{
                bg: 'blue.500',
              }}>
              <Link href={ProfileLink}>View Profile</Link>
            </Button>
          </Stack>
        </Box>
      </Flex>
    );
  }