import { useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  Img,
  Flex,
  HStack,
} from '@chakra-ui/react';
import {Link} from 'react-router-dom'
import { BsArrowUpRight} from 'react-icons/bs';
import ServiceCloud from '../../assets/service_cloud.png'
import ServiceProduct from '../../assets/service_product.png'
import AOS from 'aos'

export default function Howwedo() {

    useEffect(() => {
        AOS.init({duration:1000});
        AOS.refresh();
      },[])

  return (
    <Flex direction={'column'} justify='space-between' alignItems={'center'} >
        <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color='#22577A' mb='2%' data-aos='fade-down'>Our Services</Text>
        <Flex direction={{base:'column',md:'row'}} justifyContent='space-evenly'>
            <Box data-aos='fade-right' mx={{base:'0',md:'20%'}} >
                <Box
                    w="xs"
                    rounded={'xl'}
                    my={5}
                    mx={[0, 5]}
                    overflow={'hidden'}
                    bg="rgba(255,255,255,0.06)"
                    boxShadow={'xl'}
                    backdropFilter='auto'
                    backdropBlur='20px'
                    borderWidth='1px'
                    borderColor='rgba(255,255,255,0.1)'
                    //  bgGradient='linear(to-tr, #22577A, white, #80ED99)'
                    >
                    <Box h={'150px'} >
                    <Img
                        src={
                        ServiceCloud
                        }
                        roundedTop={'sm'}
                        objectFit="cover"
                        h="full"
                        w="full"
                        alt={'Blog Image'}
                    />
                    </Box>
                    <Box p={4}>
                    <Box
                        bg="#22577A"
                        display={'inline-block'}
                        px={2}
                        py={1}
                        color="white"
                        mb={2}>
                        <Text fontSize={'xs'} fontWeight="medium">
                        Service
                        </Text>
                    </Box>
                    <Heading color={'black'} fontSize={'2xl'} noOfLines={1}>
                        Cloud Solutions
                    </Heading>
                    <Text color={'gray.800'} noOfLines={5} >
                        We specialize in providing a wide range of cloud-based solutions that enable businesses to streamline their operations, reduce costs, and improve their overall efficiency.
                    </Text>
                    </Box>
                    <Link to='/services'>
                    <HStack borderTop={'1px'} color="black">
                    <Flex
                        p={4}
                        alignItems="center"
                        justifyContent={'space-between'}
                        roundedBottom={'sm'}
                        cursor={'pointer'}
                        w="full">
                        <Text fontSize={'md'} fontWeight={'semibold'}>
                        View more
                        </Text>
                        <BsArrowUpRight />
                    </Flex>
                    </HStack>
                    </Link>
                </Box>
                <Box rounded='full' boxSize={'250'} bgGradient='linear(to-tr, #22577A, #80ED99)'  filter='auto' blur='2px' position='relative' top={{base:'-28vh',md:'-20%'}} left={{base:'-30%',md:'-20%'}} zIndex='-7'/>
                <Box rounded='full' boxSize={'150'} bgGradient='linear(to-tr, #22577A, #80ED99)' filter='auto' blur='2px' position='relative' top={{base:'-78vh',md:'-70%'}} left={{base:'80%',md:'70%'}} zIndex='-7'/>
            </Box>
            <Box data-aos='fade-left' mx={{base:'0',md:'20%'}} mt={{base:'-100%',md:'0'}}>
                <Box
                    w="xs"
                    rounded={'xl'}
                    my={5}
                    mx={[0, 5]}
                    overflow={'hidden'}
                    bg="rgba(255,255,255,0.06)"
                    boxShadow={'xl'}
                    backdropFilter='auto'
                    backdropBlur='10px'
                    borderWidth='1px'
                    borderColor='rgba(255,255,255,0.1)'
                    //  bgGradient='linear(to-tr, #22577A, white, #80ED99)'
                    >
                    <Box h={'150px'} >
                    <Img
                        src={
                        ServiceProduct
                        }
                        roundedTop={'sm'}
                        objectFit="cover"
                        h="full"
                        w="full"
                        alt={'Blog Image'}
                    />
                    </Box>
                    <Box p={4}>
                    <Box
                        bg="#22577A"
                        display={'inline-block'}
                        px={2}
                        py={1}
                        color="white"
                        mb={2}>
                        <Text fontSize={'xs'} fontWeight="medium">
                        Service
                        </Text>
                    </Box>
                    <Heading color={'black'} fontSize={'2xl'} noOfLines={1}>
                        Product Engineering
                    </Heading>
                    <Text color={'gray.800'} noOfLines={5} >
                    We specialize in helping businesses bring their ideas to life with our innovative and robust engineering solutions, starting from ideation to deployment, and everything in between.
                    </Text>
                    </Box>
                    <Link to='/productservices'>
                    <HStack borderTop={'1px'} color="black">
                    <Flex
                        p={4}
                        alignItems="center"
                        justifyContent={'space-between'}
                        roundedBottom={'sm'}
                        cursor={'pointer'}
                        w="full">
                        <Text fontSize={'md'} fontWeight={'semibold'}>
                        View more
                        </Text>
                        <BsArrowUpRight />
                    </Flex>
                    </HStack>
                    </Link>
                </Box>
                <Box rounded='full' boxSize='300' bgGradient='linear(to-tr, #22577A, #80ED99)'  filter='auto' blur='2px' position='relative' top={{base:'-50vh',md:'-40%'}} left={{base:'75%',md:'75%'}} zIndex='-7'/>
            </Box>
        </Flex>
    </Flex>
  );
}