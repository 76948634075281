import React, {useState, useEffect} from 'react'
import {PhoneIcon,EmailIcon, CloseIcon} from '@chakra-ui/icons'
import {Button, useDisclosure, ScaleFade, Flex, Text, Image, Link, Box} from '@chakra-ui/react'
import  {IoLocationSharp} from 'react-icons/io5'
import Whatsapp from '../assets/WhatsAppButton.png'


export default function Explore() {

    const { isOpen, onToggle } = useDisclosure()

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {

        const handleScroll = () => {
        const scrollHeight = document.body.scrollHeight;
        const scrollTop = document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        setIsVisible((scrollTop + windowHeight + 50) < scrollHeight);
        // console.log(scrollHeight, scrollTop, windowHeight, scrollTop + windowHeight + 10, scrollTop + windowHeight + 10 < scrollHeight, isVisible)
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <Box style={{ display: isVisible ? 'block' : 'none' }}>
        <Button p='8' 
                    bg='#22577A' 
                    color='white' 
                    rounded='full' 
                    _hover={{boxShadow:'5xl'}} 
                    boxShadow='xl'
                    position="fixed"
                    bottom={5}
                    right={5}
                    onClick={onToggle}
        >
            <PhoneIcon/>
        </Button>
        <ScaleFade in={isOpen} unmountOnExit='true' initialScale={0.5}>
            <Box
            shadow='md'
            position="fixed"
            bottom={20}
            right={20}
            >
            <Flex direction='column' align='flex-start' justify='space-around' p={5} bgGradient='linear(to-tr, #38A3A5, #C7F9CC)' color='black' rounded='2xl' boxShadow='2xl'>
                <Button onClick={onToggle} position='absolute' top='5' right='8' bgColor='#C7F9CC'><CloseIcon/></Button>
                <Text fontSize='2xl' my='3' fontWeight='semibold'>Contact Information</Text>
                <Text my='2'> <PhoneIcon/> +91 8618144764 </Text>
                <Text my='2'> <EmailIcon/> info@usrya.com </Text>
                <Text my='2'> <IoLocationSharp className='location'/> RRL Towers, 3rd Floor, Cabin 3, Sompura, Bengaluru, Karnataka - 562125 </Text>
                <Link href='https://wa.me/918618144764' isExternal><Image src={Whatsapp} boxSize='60%'></Image></Link>
            </Flex>
            </Box>
        </ScaleFade>
        </Box>
    )
}
