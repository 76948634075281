import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from '@chakra-ui/react'

export default function Explore({redirect}) {
    return (
        <Link to={redirect}>
            <Button px='6' 
                        bg='#22577A' 
                        color='white' 
                        rounded='full' 
                        _hover={{boxShadow:'5xl'}} 
                        boxShadow='xl'
            >
                EXPLORE NOW
            </Button>
        </Link>
    )
}
