import React from 'react'
import {NavLink} from 'react-router-dom'
import {Box, Flex, Text, Menu, MenuButton, MenuList, MenuItem, Button, Image, Link} from "@chakra-ui/react"
import { CloseIcon, HamburgerIcon, ChevronDownIcon} from "@chakra-ui/icons"
import Logo from "../assets/Logo.png"


export default function Navbar() {
    const [show, setShow] = React.useState(false)
    const toggleMenu = () => setShow(!show)

    
    return (
      <Box>
        <Flex as="nav" align="center" justify="space-around" wrap="wrap" w="100%" mb={0} p={10}
        // bg="black"
        color="black"
        >
        <Flex align="center">
              <Image  w="80%"  src={Logo}/>
        </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <HamburgerIcon />}
      </Box>
      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        height={{ base: show ? "max-content" : '0', md: 'max-content' }}
        flexBasis={{ base: "100%", md: "auto" }}
        className='dropdown'
      >
        <Flex
          align="center"
          justify={{base:"center", md:"flex-end"}}
          direction={{base:"column", md:"row"}}
          pt={[4, 4, 0, 0]}
          fontFamily='sans-serif'
        >
          <Text mb={{ base: 8, sm: 0 }} mr={{ base: 0, sm: 14 }} display="block" p={{base:4,md:0}} fontWeight="hairline" fontSize="sm" onClick={toggleMenu}>
            <NavLink exact to="/" >HOME</NavLink>
          </Text>
          <Menu placement='bottom' closeOnSelect={true}>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}  _hover={{bg:'#22577A', color:'white'}} fontWeight='hairline' fontSize={'sm'} bg='white'  mr={{base:0,sm:10}} mb={{base:8,sm:0}}>
              SERVICES
              </MenuButton>
              <MenuList >
                <MenuItem _hover={{bg:'#22577A', color:'white'}} onClick={toggleMenu}><NavLink exact to='/services'>Cloud Services</NavLink></MenuItem>
                <MenuItem _hover={{bg:'#22577A', color:'white'}} onClick={toggleMenu}><NavLink exact to='/productservices'>Product Engineering</NavLink></MenuItem>
                <MenuItem _hover={{bg:'#22577A', color:'white'}} onClick={toggleMenu}><NavLink exact to='/hrservices'>HR Solutioning</NavLink></MenuItem>
                {/* <MenuItem _hover={{bg:'#22577A', color:'white'}} onClick={toggleMenu}><NavLink exact to='/skillservices'>Skill Builder</NavLink></MenuItem> */}
              </MenuList>
          </Menu>  
          <Text mb={{ base: 8, sm: 0 }} mr={{ base: 0, sm: 14 }} display="block" p={{base:4,md:0}} fontWeight="hairline" fontSize="sm" onClick={toggleMenu}>
            <NavLink exact to="/about" >ABOUT US</NavLink>
          </Text>
          <Text mb={{ base: 8, sm: 0 }} mr={{ base: 0, sm: 14 }} display="block" p={{base:4,md:0}} fontWeight="hairline" fontSize="sm" onClick={toggleMenu}>
            <NavLink exact to="/career" >CAREERS</NavLink>
          </Text>
          <Text mb={{ base: 8, sm: 0 }} mr={{ base: 0, sm: 14 }} display="block" p={{base:4,md:0}} fontWeight="hairline" fontSize="sm" onClick={toggleMenu}>
            <NavLink exact to="/blog" >BLOG</NavLink>
          </Text>
          <Text mb={{ base: 8, sm: 0 }} mr={{ base: 0, sm: 14 }} display="block" p={{base:4,md:0}} fontWeight="hairline" fontSize="sm" onClick={toggleMenu}>
            <NavLink exact to="/contact" >CONTACT US</NavLink>
          </Text>
          <Text mb={{ base: 8, sm: 0 }} mr={{ base: 0, sm: 14 }} display="block" p={{base:4,md:0}} fontWeight="hairline" fontSize="sm" onClick={toggleMenu}>
          <Link href={"https://www.grihome.in"}>GRIHOME</Link>
          </Text>
        </Flex>
      </Box>
    </Flex>
    </Box>
    
    )
}
