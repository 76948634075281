import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack,
  } from '@chakra-ui/react';
  import { CheckIcon } from '@chakra-ui/icons';

const features = [
    {
        id: 1,
        title: 'Media & Entertainment',
    },
    {
        id: 2,
        title: 'Automobiles',
    },
    {
        id: 3,
        title: 'Retail & Consumer Markets',
    },
    {
        id: 4,
        title: 'Digital Agencies',
    },
    {
        id: 5,
        title: 'Financial Services',
    },
    {
        id: 6,
        title: 'E-commerce',
    },
    {
        id: 7,
        title: 'Education & Training',
    },
    {
        id: 8,
        title: 'Pharmaceuticals',
    },
    {
        id: 9,
        title: 'Healthcare & Life Sciences',
    },
    {
        id: 10,
        title: 'Tourism & Hospitality',
    },
    {
        id: 11,
        title: 'Public Sector & Government',
    },
    {
        id: 12,
        title: 'Manufacturing',
    },
    {
        id: 13,
        title: 'Information Technology',
    },
    {
        id: 14,
        title: 'Cloud Migration Services',
    },
    {
        id: 15,
        title: 'Managed AWS Clouds',
    },
]
  
  export default function Verticals() {
    return (
      <Box p={4} my='5%'>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={'3xl'}>Industry Verticals Supported</Heading>
          <Text color={'gray.600'} fontSize={'xl'}>
            Below are the industry verticals that we support in terms of our services.
          </Text>
        </Stack>
  
        <Container maxW={'6xl'} mt={10}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {features.map((feature) => (
              <HStack key={feature.id} align={'top'}>
                <Box color={'green.400'} px={2}>
                  <Icon as={CheckIcon} />
                </Box>
                <VStack align={'start'}>
                  <Text fontWeight={600}>{feature.title}</Text>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    );
  }