import {
  Box,
  Heading,
  Text,
  Image,
  List,
  ListIcon,
  ListItem
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons';

export default function ServiceDesc({Photo,Type,Title,Content,ContentImg}) {
  return (
    <Box mx={{base:'0',md:'20%'}}>
        <Box
            w="full"
            rounded={'xl'}
            maxHeight={{base:'60vh',md:'80vh'}}
            // my={5}
            mx={[0, 0]}
            overflowX={'hidden'}
            overflowY={'scroll'}
            bg="rgba(255,255,255,0.06)"
            boxShadow={'2xl'}
            backdropFilter='auto'
            backdropBlur='20px'
            borderWidth='1px'
            borderColor='rgba(255,255,255,0.1)'
            //  bgGradient='linear(to-tr, #22577A, white, #80ED99)'
            >
            <Box h={'250px'} >
            <Image
                src={
                Photo
                }
                roundedTop={'sm'}
                objectFit='cover'
                h="full"
                w="full"
                alt={'Blog Image'}
            />
            </Box>
            <Box p={10} bgGradient = 'linear(to-tr, #38A3A5, #C7F9CC)'>
            <Box
                bg="#22577A"
                display={'inline-block'}
                px={2}
                py={1}
                color="white"
                mb={2}>
                <Text fontSize={'xs'} fontWeight="medium">
                {Type}
                </Text>
            </Box>
            <Heading color={'black'} fontSize={'2xl'}>
                {Title}
            </Heading>
            <Text color={'gray.800'} mt='2%'>
                <List spacing={3}>
                    {/* {Content.forEach(element => {
                        console.log(element)
                    })} */}
                    <ListItem id={'image'}>
                        {ContentImg}
                    </ListItem>
                    {Content.map((cont,key) => (
                        <ListItem id={key}>
                        <ListIcon as={CheckCircleIcon} color="green.900" />
                        {cont}
                        </ListItem>
                    ))}
                    {/* <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.900" />
                    We specialize in providing a wide range of cloud-based solutions that enable businesses to streamline their operations, reduce costs, and improve their overall efficiency.
                    </ListItem>
                    <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.900" />
                    We specialize in providing a wide range of cloud-based solutions that enable businesses to streamline their operations, reduce costs, and improve their overall efficiency.
                    </ListItem>
                    <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.900" />
                    We specialize in providing a wide range of cloud-based solutions that enable businesses to streamline their operations, reduce costs, and improve their overall efficiency.
                    </ListItem>
                    <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.900" />
                    We specialize in providing a wide range of cloud-based solutions that enable businesses to streamline their operations, reduce costs, and improve their overall efficiency.
                    </ListItem>
                    <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.900" />
                    We specialize in providing a wide range of cloud-based solutions that enable businesses to streamline their operations, reduce costs, and improve their overall efficiency.
                    </ListItem> */}
                </List>
            </Text>
            </Box>
            {/* <HStack borderTop={'1px'} color="black">
            <Flex
                p={4}
                alignItems="center"
                justifyContent={'space-between'}
                roundedBottom={'sm'}
                cursor={'pointer'}
                w="full">
                <Text fontSize={'md'} fontWeight={'semibold'}>
                View more
                </Text>
                <BsArrowUpRight />
            </Flex>
            </HStack> */}
        </Box>
    </Box>
  );
}