
import {
    Box,
    Center,
    Heading,
    Text,
    Stack,
    Avatar,
    Image,
    Button,
    Link
  } from '@chakra-ui/react';
  import { BsArrowUpRight } from 'react-icons/bs';
  
  export default function blogPostWithImage({Img, Title, Content, BlogLink, BlogAvatar, Name, Date}) {
    return (
      <Center py={6}>
        <Box
          maxW={'445px'}
          w={'full'}
          bg={'white'}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          m={2}
          overflow={'hidden'}>
          <Box
            bg={'white'}
            mt={-6}
            mx={-6}
            mb={6}
            pos={'relative'}>
            <Image
              src={
                Img
              }
              objectFit='cover'
            />
          </Box>
          <Stack>
            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={800}
              fontSize={'sm'}
              letterSpacing={1.1}>
              Blog
            </Text>
            <Heading
              color={'black'}
              fontSize={'2xl'}
              fontFamily={'body'}>
              {Title}
            </Heading>
            <Text color={'gray.500'}>
              <div dangerouslySetInnerHTML={Content}></div>
            </Text>
          </Stack>
          <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            <Link href={BlogLink} isExternal >Read more</Link>
            <BsArrowUpRight/>
          </Button>
          <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
            <Avatar
              src={BlogAvatar}
              alt={'Author'}
            />
            <Stack direction={'column'} spacing={0} fontSize={'sm'}>
              <Text fontWeight={600}>{Name}</Text>
              <Text color={'gray.500'}>{Date}</Text>
            </Stack>
          </Stack>
        </Box>
      </Center>
    );
  }