import React, {useEffect, useState} from 'react'
import { Box, IconButton, useBreakpointValue} from '@chakra-ui/react'
import LandImg1 from '../../assets/HeroSVG1.png'
import LandImg2 from '../../assets/HeroSVG2.png'
import LandImg3 from '../../assets/HeroSVG3.png'
import LandImg4 from '../../assets/HeroSVG4.png'
import CarouselComp from './CarouselComp'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos'

import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

export default function Hero() {
    useEffect(() => {
        AOS.init({duration:1000});
        AOS.refresh();
      },[])

      const [slider, setSlider] = useState()

      const settings = {
        className: "center",
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        arrows:true,
        easing:true,
        accessibility:true,
        autoplay:true,
        autoplaySpeed:3000,
        pauseOnHover: false,
        lazyload: true,
        responsive: [
            {
              breakpoint: 960,
              settings: {
                className: "center",
                dots: true,
                infinite: true,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                arrows:true,
                easing:true,
                accessibility:true,
                autoplay:true,
                autoplaySpeed:3000,
                pauseOnHover: false,
                lazyload: true,
              }
            }]
    };


    const top = useBreakpointValue({ base: '90%', md: '50%' });
    const side = useBreakpointValue({ base: '30%', md: '10px' });

    return (
        <div style={{position:'relative'}}>
        <Box rounded='full' boxSize={{base:'400',md:'900'}} bgGradient='linear(to-tr, #38A3A5, #C7F9CC)'  filter='auto' blur='80px' position='absolute' top={{base:'-50vh',md:'-70%'}} left={{base:'75%',md:'80%'}} zIndex='-7'/>
        <Box rounded='full' boxSize={{base:'200',md:'600'}} bgGradient='linear(to-tr, #38A3A5, #C7F9CC)'  filter='auto' blur='80px' position='absolute' top={{base:'20vh',md:'20%'}} left={{base:'-30%',md:'-20%'}} zIndex='-7'/>
        <Box mb='5%'>
            <IconButton
                aria-label="left-arrow"
                colorScheme="red"
                borderRadius="full"
                position="absolute"
                left={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider.slickPrev()}>
                <BiLeftArrowAlt />
            </IconButton>
            {/* Right Icon */}
            <IconButton
                aria-label="right-arrow"
                colorScheme="red"
                borderRadius="full"
                position="absolute"
                right={side}
                top={top}
                transform={'translate(0%, -50%)'}
                zIndex={2}
                onClick={() => slider.slickNext()}>
                <BiRightArrowAlt />
            </IconButton>
            <Slider ref={c => (setSlider(c))} {...settings} >
                <CarouselComp Heading1={'EMPOWERING YOUR'} Heading2={'DIGITAL TRANSFORMATION'} Tagline1={'Revolutionize your business with cutting-edge IT solutions'} Tagline2={'Partner with us for unparalleled technology-driven success'} Img={LandImg1} Linkto={'/about'}/>
                <CarouselComp Heading1={'EMPOWERING YOUR'} Heading2={'CLOUD FUTURE'} Tagline1={'Cloud services for your every need with best in class solutions. '} Tagline2={'Partner with us for your seamless cloud journey'} Img={LandImg2} Linkto={'/services'}/>
                <CarouselComp Heading1={'EMPOWERING YOUR'} Heading2={'PRODUCT JOURNEY'} Tagline1={'Product Development Services catering to all your needs '} Tagline2={'integrated with cutting edge artificial intelligence solutions'} Img={LandImg3} Linkto={'/productservices'}/>
                <CarouselComp Heading1={'EMPOWERING YOUR'} Heading2={'UPSKILLING NEEDS'} Tagline1={'Various programs for upskilling your students or employees. '} Tagline2={'Partner with us for diving into creative and informative trainings'} Img={LandImg4} Linkto={'/skillservices'}/>
            </Slider>
        </Box>
        </div>
    )
}