import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from '@chakra-ui/react'

export default function ContactButton() {
    return (
        <Link to='/contact'>
            <Button px='8' mx='2' 
                        bg='#38A3A5' 
                        color='white' 
                        rounded='full' 
                        _hover={{boxShadow:'5xl'}} 
                        boxShadow='xl'
            >
                CONTACT US
            </Button>
        </Link>
    )
}
