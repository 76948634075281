import { Box, Stack, Image,Text } from "@chakra-ui/react";

export default function ServiceComp({Icon,Title}) {
    return (
        <Box 
            // maxW={'750px'}
            w={{base:'full',md:'full'}}
            overflow={'hidden'}
            // p='1'
            // boxShadow='2xl'
            m={{base:1,md:2}}
            >
            

        <Stack align={'center'} direction='row' alignContent={'center'} textAlign='center' wrap='nowrap' m={0}>
            <Image src={Icon} boxSize={{base:'15%',md:'12%'}}></Image>
            <Text fontSize={{base:'lg',md:'xl'}} fontWeight={500} >
                {Title}
            </Text>
        </Stack>

        </Box>
    )
}