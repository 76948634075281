import React from 'react'
import Client1 from '../assets/apn.png'
import Client2 from '../assets/crayon.jpeg'
import Client3 from '../assets/auroin.jpeg'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Flex, Stack, Text, Box } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';

export default function Clients() {

    const settings = {
        className: "center",
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        arrows:true,
        easing:true,
        accessibility:true,
        autoplay:true,
        autoplaySpeed:3000,
        responsive: [
            {
              breakpoint: 960,
              settings: {
                className: "center",
                dots: true,
                infinite: true,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                arrows:false,
                easing:true,
                accessibility:true,
                autoplay:true,
                autoplaySpeed:3000,
              }
            }]
    };

    return (
        <Box mt='-10%' mb='5%'>
            <Flex direction='column' align='center' justify='center' m='5' data-aos='fade-down'>
                <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color='#22577A' mb='2%' data-aos='fade-down'>Our Partners</Text>
            </Flex>
            <Stack mb='5' data-aos='fade-up'>
                <Slider {...settings} >
                    <Image src={Client1} objectFit='contain' boxSize='100px' p='5' m='5'></Image>
                    <Image src={Client2} objectFit='contain' boxSize='100px' p='5' m='5'></Image>
                    <Image src={Client3} objectFit='contain' boxSize='200px' p='5' m='s5'></Image>
                </Slider>
            </Stack>
        </Box>
    )
}
