import { Flex,Tabs, TabList, TabPanels, Tab, TabPanel,Box,Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, Center, Heading} from '@chakra-ui/react'
import React from 'react'
import ServiceComp from './landingPageComonents/ServiceComp'
import Security from '../assets/security.png'
import Consult from '../assets/consult.png'
import Migrate from '../assets/migration.png'
import Manage from '../assets/manage.png'
import Billing from '../assets/billing.png'
import Automate from '../assets/automation.png'
import CDN from '../assets/cdn.png'
import DR from '../assets/data_recovery.png'
import Backup from '../assets/backup.png'
import ServiceDesc from './ServiceDesc'
import First from '../assets/cloud/first.jpg'
import Second from '../assets/cloud/second.jpg'
import Third from '../assets/cloud/third.jpg'
import Fourth from '../assets/cloud/fourth.jpg'
import Fifth from '../assets/cloud/fifth.jpg'
import Sixth from '../assets/cloud/sixth.jpg'
import Seventh from '../assets/cloud/seventh.jpg'
import Eighth from '../assets/cloud/eighth.jpeg'
import Ninth from '../assets/cloud/ninth.png'
import Benefits from './Benefits'
import Verticals from './Verticals'
import ContactButton from './ContactButton'

const offerings = [
  {
    icon: Security,
    photo: First,
    type:'Offering',
    title: 'Security & Compliance',
    content:[
      "Security and compliance are two critical aspects of cloud computing that organizations must consider when migrating to the cloud. Security refers to the protection of data and systems from unauthorized access, theft, or damage, while compliance refers to adherence to regulations and standards related to data privacy and security.",
      "Cloud service providers offer a range of security measures to protect against cyber threats, including access controls, encryption, firewalls, and intrusion detection and prevention systems. Organizations must also ensure that they have appropriate security measures in place, such as strong passwords, multi-factor authentication, and regular security audits and testing.",
      "Compliance with regulations such as the General Data Protection Regulation (GDPR), Health Insurance Portability and Accountability Act (HIPAA), and Payment Card Industry Data Security Standard (PCI DSS) is essential for organizations that handle sensitive data. Cloud service providers can help organizations comply with these regulations by providing data protection features, such as data encryption, access controls, and audit logs.",
      "Organizations must also ensure that they have appropriate policies and procedures in place to comply with regulations, such as data retention and disposal policies, incident response plans, and employee training programs.",
      "Overall, security and compliance are critical components of cloud computing that organizations must address to protect their data and systems, and to comply with regulatory requirements. Cloud service providers offer a range of security and compliance features, but it is ultimately the responsibility of organizations to ensure that they have appropriate measures in place to protect their data and comply with regulations."
    ]
  },
  {
    icon: Consult,
    photo: Second,
    type:'Offering',
    title: 'Cloud Consulting',
    content:[
      "Cloud consulting refers to the practice of providing expert advice and guidance to businesses or organizations that are planning to or have already adopted cloud computing technologies. Cloud computing involves the use of remote servers, storage, and computing resources over the internet to deliver on-demand services to users.",
      "Cloud consulting services may include assessing an organization's IT infrastructure, identifying business objectives and challenges, recommending cloud solutions, designing and implementing cloud-based systems, and providing ongoing support and maintenance.",
      "We work with different cloud services providers, such as Amazon Web Services (AWS), Microsoft Azure, or Google Cloud Platform, to help organizations determine the best cloud solutions to meet their needs. They may also help businesses migrate their existing IT systems and data to the cloud, ensuring that the transition is smooth and secure.",
      "Organizations maximize the benefits in terms of cost savings, scalability, flexibility, and improved efficiency while minimizing the risks and challenges associated with cloud adoption."
    ]
  },
  {
    icon: Migrate,
    photo: Third,
    type:'Offering',
    title: 'Cloud Migration',
    content:[
      "Cloud migration is the process of moving an organization's digital assets, such as data, applications, and IT resources, from an on-premises environment to a cloud-based infrastructure. The objective of cloud migration is to leverage the benefits of cloud computing, including scalability, cost savings, agility, and enhanced security.",
      <>
      There are several steps involved in the cloud migration process, including:
      <br></br>
      - Assessing the existing IT infrastructure and identifying which applications and data are suitable for migration to the cloud.
      <br></br>
      - Choosing the right cloud service provider and determining which cloud deployment model, such as public, private, or hybrid cloud, is best suited to the organization's needs.
      <br></br>
      - Creating a migration plan that outlines the order of migration and timelines, as well as identifying any potential risks and challenges.
      <br></br>
      - Preparing the applications and data for migration, such as updating software, optimizing databases, and ensuring compatibility with the cloud environment.
      <br></br>
      - Executing the migration plan, which involves transferring data and applications to the cloud environment.
      <br></br>
      - Testing and validating the migrated applications and data to ensure that they are functioning correctly.
      <br></br>
      - Optimizing the cloud infrastructure to ensure that it is meeting the organization's needs and making any necessary adjustments.,
      </>,
      "Overall, cloud migration can be a complex and challenging process, but with proper planning and execution, it can help organizations achieve significant benefits in terms of cost savings, scalability, and agility."
    ]
  },
  {
    icon: Manage,
    photo: Fourth,
    type:'Offering',
    title: 'Managed Services',
    content:[
      "Cloud managed services refer to the outsourcing of day-to-day management and maintenance of an organization's cloud infrastructure to a third-party service provider. These services typically include monitoring, automation, optimization, and security of the cloud environment.",
      "Cloud managed service providers (MSPs) are responsible for managing the cloud infrastructure, including servers, storage, networking, and applications, as well as ensuring that the cloud environment is operating efficiently and effectively.",
      <>
      Some of the benefits of cloud managed services include:
      <br></br>
      <Text fontWeight={'bold'}>Cost savings:</Text> By outsourcing cloud management, organizations can save money on hiring and training in-house staff, as well as on hardware and software costs.
      <br></br>
      <Text fontWeight={'bold'}>Increased efficiency:</Text> Managed service providers use automation and monitoring tools to streamline operations, reduce downtime, and improve performance.
      <br></br>
      <Text fontWeight={'bold'}>Scalability:</Text> Cloud MSPs can help organizations scale their infrastructure up or down quickly to meet changing business needs.
      <br></br>
      <Text fontWeight={'bold'}>Enhanced security:</Text> Cloud MSPs provide security services to help protect against cyber threats and ensure that data is secure and compliant with regulations.
      <br></br>
      <Text fontWeight={'bold'}>Access to expertise:</Text> Managed service providers have a team of experts who are up-to-date with the latest cloud technologies and can provide guidance and advice on best practices.
      </>,
      "Cloud managed services can be customized to meet the specific needs of each organization, and typically operate under a subscription-based model, with the MSP providing ongoing support and maintenance for a monthly fee."
    ]
  },
  {
    icon: Billing,
    photo: Fifth,
    type:'Offering',
    title: 'Billing & Cost Optimization',
    content:[
      "Cloud billing and optimization refer to the management of cloud infrastructure costs to ensure that organizations are maximizing their return on investment (ROI) in the cloud. Cloud computing offers organizations the ability to pay only for the resources they use, but without proper management, costs can quickly spiral out of control.",
      "Cloud billing involves monitoring and analyzing cloud usage to identify areas where costs can be reduced. This can include optimizing cloud storage, reducing server instances, and selecting the appropriate pricing models for cloud services.",
      "Cloud optimization involves fine-tuning cloud infrastructure to improve performance and reduce costs. This can include scaling resources up or down based on demand, optimizing data transfer and storage, and selecting the right cloud service provider and pricing model for specific workloads.",
      "Cloud billing and optimization can be complex and time-consuming, but there are tools and services available to help organizations manage costs and improve efficiency. Cloud service providers offer billing dashboards and tools to help organizations monitor and analyze cloud usage, and there are third-party tools and services available to help organizations optimize their cloud infrastructure and reduce costs.",
      "Overall, effective cloud billing and optimization can help organizations achieve significant cost savings while ensuring that they are getting the most out of their cloud infrastructure. By continually monitoring and fine-tuning cloud usage, organizations can ensure that they are only paying for what they need, while maintaining high levels of performance and efficiency."
    ]
  },
  {
    icon: Automate,
    photo: Sixth,
    type:'Offering',
    title: 'Automation & DevOps',
    content:[
      "Automation and DevOps are two closely related concepts that have become increasingly important in modern software development. DevOps is a software development methodology that emphasizes collaboration and communication between development and operations teams to improve the speed and quality of software delivery.",
      "Automation is a key component of DevOps, as it allows organizations to streamline development and deployment processes and reduce manual errors. Automation tools can help automate tasks such as code testing, deployment, and monitoring, freeing up developers and operations teams to focus on higher-level tasks.",
      <>
      Some of the benefits of automation and DevOps include:
      <br></br>
      <Text fontWeight={'bold'}>Improved speed and efficiency:</Text> Automation and DevOps can help organizations deliver software faster and more efficiently, with fewer errors and delays.
      <br></br>
      <Text fontWeight={'bold'}>Enhanced collaboration:</Text> DevOps emphasizes collaboration and communication between teams, leading to better alignment and faster decision-making.
      <br></br>
      <Text fontWeight={'bold'}>Increased scalability:</Text> Automation and DevOps allow organizations to scale their software development and deployment processes quickly and easily, without sacrificing quality or performance.
      <br></br>
      <Text fontWeight={'bold'}>Improved quality:</Text> Automation tools can help organizations identify and fix errors early in the development process, leading to higher-quality software.
      <br></br>
      <Text fontWeight={'bold'}>Enhanced security:</Text> DevOps teams can build security into their development processes from the beginning, reducing the risk of security vulnerabilities and breaches.
      </>
      ,
      "Overall, automation and DevOps are critical components of modern software development, allowing organizations to deliver high-quality software faster and more efficiently. By emphasizing collaboration, communication, and automation, organizations can improve their development processes and achieve significant benefits in terms of speed, efficiency, scalability, quality, and security."
    ]
  },
  {
    icon: CDN,
    photo: Seventh,
    type:'Offering',
    title: 'Content Delivery Solutions',
    content:[
      "Content delivery and edge solutions are technologies that enable fast and efficient delivery of digital content, such as websites, videos, and other media, to end-users.",
      "Content delivery networks (CDNs) are a type of technology that can improve the speed and reliability of content delivery. CDNs use a network of servers distributed across multiple locations to cache content and serve it to end-users from the server closest to them. This reduces latency and ensures that users can access content quickly and reliably, regardless of their location.",
      "Edge solutions, on the other hand, refer to a type of computing infrastructure that brings processing and storage closer to end-users, typically at the network edge. Edge solutions can include edge computing, which allows for faster data processing and analysis by moving computing resources closer to the source of data, and edge caching, which caches content at the network edge to improve content delivery speed.",
      "Together, CDNs and edge solutions can help organizations improve the speed and reliability of their digital content delivery, which is critical for businesses that rely on digital content to drive revenue or engage with customers. By using CDNs and edge solutions, organizations can ensure that their content is delivered quickly and reliably, regardless of the location of end-users, improving the user experience and potentially increasing revenue.",
      "Overall, security and compliance are critical components of cloud computing that organizations must address to protect their data and systems, and to comply with regulatory requirements. Cloud service providers offer a range of security and compliance features, but it is ultimately the responsibility of organizations to ensure that they have appropriate measures in place to protect their data and comply with regulations."
    ]
  },
  {
    icon: DR,
    photo: Eighth,
    type:'Offering',
    title: 'Disaster Recovery',
    content:[
      "Disaster recovery is the process of restoring critical business operations and systems in the event of a natural or man-made disaster. This can include events such as power outages, fires, floods, cyber attacks, and other disruptive incidents that can cause significant downtime or data loss.",
      <>
      Disaster recovery plans typically involve a combination of strategies, technologies, and processes that enable organizations to recover from a disaster as quickly and efficiently as possible. These can include:
      <br></br>
      <Text fontWeight={'bold'}>Data backup and recovery:</Text> Regularly backing up critical data to secure locations or cloud-based storage to enable quick recovery in case of a disaster.
      <br></br>
      <Text fontWeight={'bold'}>Redundant systems:</Text> Having redundant systems in place to ensure continuity of operations, such as backup servers or alternate power sources.
      <br></br>
      <Text fontWeight={'bold'}>Business continuity planning:</Text> Developing and implementing a comprehensive plan to ensure that critical business operations can continue during and after a disaster.
      <br></br>
      <Text fontWeight={'bold'}>Testing and maintenance:</Text> Regularly testing and maintaining disaster recovery plans to ensure they are up-to-date and effective.
      <br></br>
      <Text fontWeight={'bold'}>Cloud-based disaster recovery:</Text> Using cloud-based disaster recovery solutions to enable rapid recovery of critical systems and data in case of a disaster.
      </>,
      "Effective disaster recovery planning is critical for businesses of all sizes, as it can help minimize downtime, reduce data loss, and ensure business continuity in the event of a disaster. By developing and implementing a comprehensive disaster recovery plan, organizations can minimize the impact of a disaster on their operations and ensure that they can quickly resume business activities with minimal disruption."
    ]
  },
  {
    icon: Backup,
    photo: Ninth,
    type:'Offering',
    title: 'Backup & Archival',
    content:[
      "Backup and archival are two related but distinct concepts in data management.",
      "Backup refers to the process of creating a duplicate copy of data or applications to protect against data loss in the event of a hardware failure, human error, or other disaster. Backups can be stored on-site or off-site, and can be performed at regular intervals to ensure that the most recent data is always available in case of a data loss event.",
      "Archival, on the other hand, refers to the process of preserving data for long-term retention and compliance purposes. Archiving is typically used for data that is no longer actively used but still needs to be retained for legal, regulatory, or business reasons. Archived data is typically stored in a separate location from the primary data storage system, often using specialized storage systems designed for long-term data retention.",
      "Both backup and archival are important components of data management and protection. Backup helps ensure that critical data is always available in case of a data loss event, while archival helps ensure compliance with legal and regulatory requirements and preserves data for historical or reference purposes. By implementing backup and archival processes, organizations can ensure that their data is protected and available when needed, reducing the risk of data loss and ensuring business continuity."
    ]
  },
]

export default function CloudServices() {
    return (
      <div style={{position:'relative'}}>
        <Box rounded='full' boxSize='900' bgGradient='linear(to-tr, #38A3A5, #C7F9CC)'  filter='auto' blur='80px' position='absolute' top={{base:'-50vh',md:'-70%'}} left={{base:'75%',md:'80%'}} zIndex='-7'/>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'none',md:'block'}}>
            <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'} mb='2%'>
                Our Cloud Services
            </Text>
            <Center>
              <Heading size={'md'}>Get your "Free" AWS Architecture</Heading> <ContactButton/>
            </Center>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'left'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Tabs variant={'enclosed-colored'} orientation='vertical'>
              <TabList ml='5%'>
                {offerings.map((element,key)=>(
                  <Tab _selected={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                  </Tab>
                ))}
              </TabList>
              <TabPanels mr='5%'>
                {offerings.map((element,key)=>(
                  <TabPanel>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
        </Flex>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'block',md:'none'}}>
            <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'} mb='2%'>
                Our Cloud Services
            </Text>
            <Center>
              <Heading size={'sm'}>Get your "Free" AWS Architecture</Heading> <ContactButton/>
            </Center>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'center'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Accordion defaultIndex={0} allowToggle>
              {offerings.map((element,key) => (
                <AccordionItem id={key}>
                  <AccordionButton _expanded={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </AccordionPanel>
                </AccordionItem>
              )
              )}
            </Accordion>
        </Flex>
        <Benefits/>
        <Verticals/>
        </div>
    )
}
