import React, {useEffect, useState} from "react";
import BlogComp from './BlogComp'
import { SimpleGrid, Text } from "@chakra-ui/react";
import axios from "axios";

export default function Blogs() {

    const blogList = [
        {
            image: 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
            title: 'Boost your conversion rate',
            content: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
            link: 'https://www.google.com',
            avatar: 'https://avatars0.githubusercontent.com/u/1164541?v=4',
            name: 'Achim Rolle',
            date: 'Feb 08, 2021'
        }
    ]

    const [posts, setPosts] = useState([])

    useEffect(() => {
        axios.get('https://public-api.wordpress.com/rest/v1/sites/usryaartivision.wordpress.com/posts')
        .then( res => {
            setPosts(res.data.posts)
            console.log(res.data.posts)  
        }
        )
    },[])

    useEffect(() => {
        console.log(posts);
        posts.map((post) => {
            console.log(post)
        })
    },[posts])

    return (
        <>
        <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'} mb='2%'>
            Our Blogs
        </Text>
        <SimpleGrid columns={{base:1, md:3}} spacing={10}>
            {posts.map((post) => {
                const content = post.excerpt;
                const parsedContent = {__html:content};
                const dateString = post.date
                const transformedDate = new Date(dateString)
                const formattedDate = transformedDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  });
                return <BlogComp Img={post.featured_image} Title={post.title} Content={parsedContent} BlogLink={post.URL} BlogAvatar={post.author.avatar_URL} Name={post.author.first_name} Date={formattedDate}></BlogComp>
            })}
        </SimpleGrid>
        </>
    )
}