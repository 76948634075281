import { Flex,Tabs, TabList, TabPanels, Tab, TabPanel,Box,Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, } from '@chakra-ui/react'
import React, {useEffect} from 'react'
import ServiceComp from './landingPageComonents/ServiceComp'
import ServiceDesc from './ServiceDesc'
import Product1 from '../assets/product1.png'
import Product2 from '../assets/product2.png'
import Product3 from '../assets/product3.png'
import Product4 from '../assets/product4.png'
import First from '../assets/product/first.png'
import Second from '../assets/product/second.jpg'
import Third from '../assets/product/third.png'
import Fourth from '../assets/product/fourth.jpg'
import Ts1 from '../assets/ts1.png'
import Ts2 from '../assets/ts2.png'
import Ts3 from '../assets/ts3.png'
import Ts4 from '../assets/ts4.png'
import Ts5 from '../assets/ts5.png'
import Ts6 from '../assets/ts6.png'
import Fsd from '../assets/fsd.png'
import Mobility from '../assets/mobility.png'
import Ai from '../assets/ai.jpg'
import Bda from '../assets/bda.jpg'
import Iot from '../assets/iot.png'
import Db from '../assets/db.jpg'
import AOS from 'aos'

const offerings = [
  {
    icon: Product1,
    photo: First,
    type:'Offering',
    title: 'Product Devlopment',
    content:[
      "Product development is the process of creating a new product or improving an existing one from ideation to launch. It involves researching, designing, prototyping, testing, and refining the product to ensure that it meets the needs and expectations of the target market. The following are the key steps involved in the product development process:",
      "Idea generation: The first step in product development is to generate ideas. This can be done by brainstorming, conducting market research, or by analyzing customer feedback.",
      "Concept development: Once an idea has been generated, the next step is to develop a concept that outlines the features, benefits, and value proposition of the product.",
      "Design: The design phase involves creating detailed product specifications, creating prototypes, and testing the product to ensure that it meets the desired specifications.",
      "Testing and validation: During this phase, the product is tested to ensure that it meets the desired quality, functionality, and user experience. This may involve user testing, quality assurance testing, and other forms of validation.",
      "Launch: Once the product has been tested and validated, it is ready to be launched. This involves developing a marketing strategy, creating promotional materials, and launching the product to the target market.",
      "Post-launch evaluation: After the product has been launched, it is important to evaluate its performance and make improvements as necessary. This may involve collecting customer feedback, analyzing sales data, and making changes to the product to improve its performance.",
      "Overall, product development is a complex process that requires careful planning, execution, and evaluation to ensure that the product meets the needs of the target market and delivers value to the company."
    ]
  },
  {
    icon: Product2,
    photo: Second,
    type:'Offering',
    title: 'Application Development',
    content:[
      "Developing  cutting edge, futuristics user applications, mobile applications and enterprise applications to given customer requirements",
      "Applications could be developed in FSD, Core Java,  .net, C#,  Python technologies, etc. with AI/ML, IOT and Data Analytics integration"
    ]
  },
  {
    icon: Product3,
    photo: Third,
    type:'Offering',
    title: 'Testing Services',
    content:[
      "Testing services refer to a range of activities that involve evaluating software, applications, or systems to ensure that they meet the expected requirements and specifications. Testing services may include functional testing, non-functional testing, performance testing, security testing, compatibility testing, and user acceptance testing.",
      "Selenium: Automation of web browsers across different platforms and operating systems",
      "Jmeter: Performance Testing Automation",
      "Appium: Automation of Mobile Applications",
      "Robot Framework:  Keyword-driven approach to automate testing of different types of applications, including web, mobile, and desktop applications."
    ]
  },
  {
    icon: Product4,
    photo: Fourth,
    type:'Offering',
    title: 'Process Automation',
    content:[
      "Process automation refers to the use of technology to automate and streamline repetitive or routine tasks in a business or organization. The goal is to reduce manual effort, improve efficiency, increase productivity, and minimize errors. There are various tools used for process automation, including:",
      "Cloud managed service providers (MSPs) are responsible for managing the cloud infrastructure, including servers, storage, networking, and applications, as well as ensuring that the cloud environment is operating efficiently and effectively.",
      "Robotic Process Automation (RPA): RPA is a software technology that allows users to configure computer software or a robot to capture and interpret existing applications for processing a transaction, manipulating data, triggering responses and communicating with other digital systems.",
      "Chatbots: Chatbots are computer programs that simulate human conversation. They can be used to automate customer service and support, sales and marketing, and other business processes."
    ]
  },
  {
    icon: Ts2,
    photo: Mobility,
    type: 'Offering',
    title: 'Mobility',
    content: [
      "Mobility refers to the ability to move freely or easily, and in the context of technology, it refers to the ability to access information, communication, and services while on the move, using mobile devices such as smartphones and tablets.",
      "Mobile applications, also known as mobile apps, are software programs designed to run on mobile devices. They have become an integral part of our lives, providing a wide range of benefits and advantages.",
      <>
      Advantages of mobile applications:
      <br></br>
      1. Convenience: Mobile applications allow users to access information and services from anywhere and at any time, making it more convenient for them to use the services they need.
      <br></br>
      2. Personalization: Mobile applications can be personalized to meet the individual needs of users. They can be tailored to provide relevant information, based on the user's location, preferences, and past behavior.
      <br></br>
      3. Faster access: Mobile applications are designed to be fast and responsive, providing users with quick access to the information they need.
      <br></br>
      4. Offline access: Some mobile applications can be used offline, allowing users to access information and services even when they don't have an internet connection.
      <br></br>
      5. Improved user engagement: Mobile applications can help improve user engagement, by providing interactive features, such as push notifications, in-app messaging, and gamification.
      <br></br>
      6. Competitive advantage: Having a mobile application can give businesses a competitive advantage, by providing customers with a better user experience and improving brand recognition.
      </>,
      "Overall, the advantages of mobile applications are many and varied, and they have become an essential tool for businesses and individuals alike."
    ]
  }
]

const techstacks = [
  {
    icon: Ts1,
    photo: Fsd,
    type:'Technology Stack',
    title: 'FSD',
    content:[
      "Building responsive, cutting-edge, Futuristics, user-friendly, mobile-ready websites by using Full Stack DevelopmentTechnologies",
      "Starting from Ideation, Conceptualization, Requiremnts Gathering, Design, Development, Testing, Product Launch and maintenance of the Product",
      "Front End Technologes:  HTML5, CSS3, Bootstrap5, jQuery, JavaScript, and React ",
      "Backend Technologies: Node JS, Express JS, Rest API Building",
      "Database: MySql, Oracle, Mongo DB, etc.",
      "Tools: Postman (Rest API Testing)",
    ]
  },
  {
    icon: Ts2,
    photo: Mobility,
    type:'Technology Stack',
    title: 'Mobility',
    content:[
      "Requirements",
      "Front End Technologes:  HTML5, CSS3, Bootstrap5, jQuery, JavaScript, and React, ",
      "Backend Technologies: Node JS, Express JS, Rest API Building",
      "Database: MySql, Oracle, Mongo DB, etc.",
      "Tools: Postman (Rest API Testing), IONICS"
    ]
  },
  {
    icon: Ts3,
    photo: Ai,
    type:'Technology Stack',
    title: 'AI / ML',
    content:[
      "Building software applications that uses algorithm and statistical models to enable machines to learn from data and make predictions and decissions",
      "Tensor Flow:  Building and training machine learning models",
      "Pytorch: Building and Training Neural networks",
      "NumPy: Python Library for Data Analysis, Image Processing, and Natural Language Processing",
      "Sickit Learn: Python Library for machine learning build on top of NumPy",
      "Keras: Open Source Neural Network Library written in Python"
    ]
  },
  {
    icon: Ts4,
    photo: Bda,
    type:'Technology Stack',
    title: 'Big Data Analytics',
    content:[
      "Big data analytics refers to the process of analyzing large and complex data sets, often in real-time, to uncover hidden patterns, unknown correlations, market trends, and other useful information. It involves using advanced tools and techniques to process, store, and analyze vast amounts of data that would be impossible to manage and make sense of using traditional methods.",
      "Hadoop: Used in the processing of Large Datasets, including HDFC (Hadoop Distrubuted File Systems) and MapReduce",
      "Spark: Data processing engine that supports batch processing, real-time processing, and machine learning. It provides interface for programming in Python, Java, Scala, and R.",
      "Hive: Data warehouse system that runs on top of Hadoop. It provides an SQL-like interface for querying and analyzing data stored in Hadoop.",
      "Pig: A scripting language for analyzing large data sets, sitting over Hadoop.",
      "Cassandra: Distributed NoSQL database that provides high scalability and fault-tolerance.",
      "Tableau: Data visualization tool that allows users to create interactive dashboards and reports. "
    ]
  },
  {
    icon: Ts5,
    photo: Iot,
    type:'Technology Stack',
    title: 'IOT',
    content:[
      "The Internet of Things (IoT) refers to a network of physical devices, vehicles, home appliances, and other items embedded with sensors, software, and connectivity to enable them to collect and exchange data. These devices are often connected to the internet, allowing them to be controlled and monitored remotely.",
      "Examples: Smart Home, Wearables, Industrial IOT, Smart Cities"
    ]
  },
  {
    icon: Ts6,
    photo: Db,
    type:'Technology Stack',
    title: 'Database Services',
    content:[
      "Database services refer to in-house and cloud-based offerings that provide managed databases to users.  The goal of database services is to provide a scalable, highly available, and cost-effective solution for hosting and managing databases.",
      "Example:  mySql, Oracle, Mongo DB, Cassandra, Amazon Dynamo DB, Cloud SQL"
    ]
  }
]

export default function ProductServices() {
  useEffect(() => {
    AOS.init({duration:1000});
    AOS.refresh();
  },[])

    return (
      <div style={{position:'relative'}}>
        <Box rounded='full' boxSize='900' bgGradient='linear(to-tr, #38A3A5, #C7F9CC)'  filter='auto' blur='80px' position='absolute' top={{base:'-50vh',md:'-70%'}} left={{base:'75%',md:'80%'}} zIndex='-7'/>
        <Text fontSize={{base:'3xl',md:'5xl'}} fontWeight='950' color="#233862" textAlign={'center'} data-aos='fade-down'>
            Our Product Engineering Services
        </Text>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'none',md:'block'}}>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'left'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Tabs variant={'enclosed-colored'} orientation='vertical'>
              <TabList ml='5%'>
                {offerings.map((element,key)=>(
                  <Tab _selected={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                  </Tab>
                ))}
              </TabList>
              <TabPanels mr='5%'>
                {offerings.map((element,key)=>(
                  <TabPanel>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
        </Flex>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'block',md:'none'}}>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'center'} mx='5%' my='2%'>
                Offerings
            </Text>
            <Accordion defaultIndex={0} allowToggle>
              {offerings.map((element,key) => (
                <AccordionItem id={key}>
                  <AccordionButton _expanded={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </AccordionPanel>
                </AccordionItem>
              )
              )}
            </Accordion>
        </Flex>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'none',md:'block'}}>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'left'} mx='5%' my='2%'>
                Technology Stacks
            </Text>
            <Tabs variant={'enclosed-colored'} orientation='vertical'>
              <TabList ml='5%'>
                {techstacks.map((element,key)=>(
                  <Tab _selected={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                  </Tab>
                ))}
              </TabList>
              <TabPanels mr='5%'>
                {techstacks.map((element,key)=>(
                  <TabPanel>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
        </Flex>
        <Flex direction='column' justify='center' m='5' wrap='wrap' h='min-content' mb='5%' display={{base:'block',md:'none'}}>
            <Text fontSize={{base:'2xl',md:'4xl'}} fontWeight='850' color="#233862" textAlign={'center'} mx='5%' my='2%'>
              Technology Stacks
            </Text>
            <Accordion defaultIndex={0} allowToggle>
              {techstacks.map((element,key) => (
                <AccordionItem id={key}>
                  <AccordionButton _expanded={{bgGradient : 'linear(to-tr, #38A3A5, #C7F9CC)', rounded : '2xl', boxShadow :'2xl'}}>
                    <ServiceComp Icon={element.icon} Title={element.title}></ServiceComp>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ServiceDesc Photo={element.photo} Type={element.type} Title={element.title} Content={element.content}/>
                  </AccordionPanel>
                </AccordionItem>
              )
              )}
            </Accordion>
        </Flex>
        </div>
    )
}
